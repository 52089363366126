import React, {useState, useEffect} from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Image from "next/image";
import "tippy.js/dist/tippy.css";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import Link from "next/link";
import Skeleton from "../Skeleton/Skeleton";
import {
    networkToUse,
    nftContractAddresses,
    cardsNftContractAddresses,
    allowPassNftContractAddresses
} from '../../components/contractAddress';
import { useDispatch, useSelector } from 'react-redux';
import { addCollections } from '../../redux/collectionSlice';

const Feature_collections_carousel = () => {
    const dispatch = useDispatch();
    const savedCollections = useSelector((state) => state.collections.collections);
    const [collections, setCollections] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
      const res = await fetch('/api/fetchNftData', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          }
      });
      const {collections} = await res.json();
      setCollections(Object.values(collections));
      dispatch(addCollections(Object.entries(collections).map(([key,value]) => ({[key]: value}))));
      setIsLoading(false);
    }

    useEffect(() => {
      if (savedCollections.length === 0) {
        fetchData();
        return;
      }

      setCollections(savedCollections.map((collection) => Object.entries(collection).flat()[1]));
      setIsLoading(false);
    }, []);

  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar]}
        breakpoints={{
          // when window width is >= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          900: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1100: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        className=" card-slider-4-columns !py-5"
      >
        {isLoading ? (
            [...Array(3)].map((_, index) => (
                <SwiperSlide key={index}>
                    <article>
                        <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2xl border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
                            <Skeleton key={index} width={"100%"} height="312px" />
                        </div>
                    </article>
                </SwiperSlide>
            ))
        ) : collections.map((collection, i) => {
          const {
              contractAddress,
              moreData,
          } = collection;

          const redirectObject = {
              pathname: `/collection/${contractAddress}`,
              query: {
                  title: moreData.metadata.name,
                  description: moreData.metadata.description,
                  image: moreData.metadata.image,
                  totalCount: moreData.totalCount.toString()
              }
          }

          return (
            <SwiperSlide key={i}>
              <article>
                <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2xl border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
                  <Link href={redirectObject} >
                    <a className="flex space-x-[0.625rem]">
                      <figure className="w-[74.5%] h-full">
                        <Image
                          src={'/images/collections/collection_'+contractAddress+'_1.jpg'}
                          alt="item 1"
                          className="rounded-[0.625rem]"
                          width={150}
                          height={240}
                          objectFit="cover"
                          layout="responsive"
                        />
                      </figure>

                      <span className="flex w-1/3 flex-col space-y-[0.625rem]">
                        <img
                          src={'/images/collections/collection_'+contractAddress+'_2.jpg'}
                          alt="item 1"
                          className="h-full rounded-[0.625rem] object-cover"
                          loading="lazy"
                        />
                        <img
                          src={'/images/collections/collection_'+contractAddress+'_3.jpg'}
                          alt="item 1"
                          className="h-full rounded-[0.625rem] object-cover"
                          loading="lazy"
                        />

                        { contractAddress !== allowPassNftContractAddresses[networkToUse] && contractAddress !== cardsNftContractAddresses[networkToUse] ? (
                            <img
                            src={'/images/collections/collection_'+contractAddress+'_4.jpg'}
                            alt="item 1"
                            className="h-full rounded-[0.625rem] object-cover"
                            loading="lazy"
                            />
                          ) : null}

                      </span>

                    </a>
                  </Link>

                  <Link href={redirectObject} >
                    <a className="font-display hover:text-accent dark:hover:text-accent text-jacarta-700 mt-4 block text-base dark:text-white">
                      {moreData.metadata.name}
                    </a>
                  </Link>

                  <div className="mt-2 flex items-center justify-between text-sm font-medium tracking-tight">
                    <div className="flex flex-wrap items-center">
                      <Link href={redirectObject} >
                        <a className="mr-2 shrink-0">
                          <img
                            src={moreData.metadata.image}
                            alt="owner"
                            className="h-5 w-5 rounded-full"
                          />
                        </a>
                      </Link>
                      <span className="dark:text-jacarta-400 mr-1">by</span>
                      <Link href={redirectObject} >
                        <a className="text-accent">
                          <span>CryptoMaze</span>
                        </a>
                      </Link>
                    </div>
                    <span className="dark:text-jacarta-300 text-sm">
                      {moreData.totalCount} Items
                    </span>
                  </div>
                </div>
              </article>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {/* <!-- Slider Navigation --> */}
      <div className="group swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-left-6 after:hidden">
        <MdKeyboardArrowLeft />
      </div>
      <div className="group swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-right-6 after:hidden">
        <MdKeyboardArrowRight />
      </div>
    </>
  );
};

export default Feature_collections_carousel;
